import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toaster, Message, Button, Form, SelectPicker } from 'rsuite'
import pick from 'lodash/pick'
import { useNavigate, useParams } from 'react-router'
import { css } from 'glamor-jss'
import { set, ref, onValue } from 'firebase/database'
import { useGetDatabase } from '../db'

import MagazineEditor from './MagazineEditor'
import generateSlug from '../generateSlug'

const warnMessage = `Bist du sicher, dass du die Seite verlassen möchten, ohne vorher zu speichern?`

function MagazineCreateOrUpdateArticle() {
  const [formData, setFormData] = useState({})
  const [defaultFormData, setDefaultFormData] = useState({})
  const [isDirty, setIsDirty] = useState(false)
  const formRef = useRef(null)
  const navigate = useNavigate()
  const db = useGetDatabase()

  const { id } = useParams()
  const isInEditMode = Boolean(id)

  const unloadHandler = useCallback(
    (e) => {
      if (!isDirty) {
        return
      }

      e.preventDefault()
      e.returnValue = ''

      return warnMessage
    },
    [isDirty],
  )

  useEffect(() => {
    if (isInEditMode) {
      onValue(ref(db, `/magazine/articles/${id}`), (snapshot) => {
        const val = snapshot.val()
        console.log('LEEEL', val)
        setFormData(val)
        // for some reason default value only sets `text` correctly, the rest needs form data lel
        setDefaultFormData(val)
        setIsDirty(false)
      })
    }
  }, [db, id, isInEditMode])

  useEffect(() => {
    window.addEventListener('beforeunload', unloadHandler)

    return () => window.removeEventListener('beforeunload', unloadHandler)
  }, [unloadHandler])

  const handleSubmit = useCallback(async () => {
    const now = Date.now()
    const enrichedFormData = isInEditMode
      ? { ...formData, updated_at: now }
      : { ...formData, published_at: now, updated_at: now }

    const slimmedFormData = pick(enrichedFormData, [
      'author',
      'author_image',
      'category',
      'image_url',
      'published_at',
      'slug',
      'snippet',
      'title',
      'updated_at',
    ])

    await set(
      ref(db, `/magazine/articles/${enrichedFormData.slug}`),
      enrichedFormData,
    )

    await set(
      ref(db, `/magazine/article_list/${enrichedFormData.slug}`),
      slimmedFormData,
    )

    setIsDirty(false)
    toaster.push(
      <Message type="success" showIcon closable>
        Artikel gespeichert.
      </Message>,
    )

    if (!isInEditMode) {
      navigate(`/magazine/edit/${enrichedFormData.slug}`)
    }
  }, [db, navigate, isInEditMode, formData])

  return (
    <Form
      layout="horizontal"
      onChange={(data) => {
        setIsDirty(true)
        setFormData({ ...formData, ...data })
      }}
      formValue={formData}
      formDefaultValue={defaultFormData}
      ref={formRef}
    >
      <Form.Group>
        <Form.ControlLabel>Titel</Form.ControlLabel>
        <Form.Control
          name="title"
          onChange={(text) =>
            !isInEditMode &&
            setFormData({ ...formData, title: text, slug: generateSlug(text) })
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Slug</Form.ControlLabel>
        <Form.Control
          name="slug"
          disabled={isInEditMode}
          onChange={(text) => setFormData({ ...formData, slug: text })}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Autor</Form.ControlLabel>
        <Form.Control name="author" />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Autor-Bild</Form.ControlLabel>
        <Form.Control name="author_image" />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Kategorie</Form.ControlLabel>
        <Form.Control
          name="category"
          required
          accepter={SelectPicker}
          placeholder="Bitte auswählen"
          cleanable={false}
          data={[
            { label: 'Fashion', value: 'Fashion' },
            { label: 'Accessoires', value: 'Accessoires' },
            { label: 'Trends', value: 'Trends' },
            { label: 'Lifestyle', value: 'Lifestyle' },
          ]}
          searchable={false}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Bild-URL</Form.ControlLabel>
        <Form.Control name="image_url" />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Snippet-Text</Form.ControlLabel>
        <Form.Control rows={4} name="snippet" as="textarea" />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Meta Description</Form.ControlLabel>
        <Form.Control name="meta_description" />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Meta Title</Form.ControlLabel>
        <Form.Control name="meta_title" />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Artikel-Text</Form.ControlLabel>
        <Form.Control name="text" accepter={MagazineEditor} />
      </Form.Group>
      <Button
        {...css({ position: 'fixed', bottom: 10, left: 10 })}
        appearance="primary"
        onClick={handleSubmit}
      >
        Speichern
      </Button>
    </Form>
  )
}
export default MagazineCreateOrUpdateArticle
