import React, { createContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Home from './Home'
import CategoriesTable from './CategoriesTable'
import BrandsOverview from './BrandsOverview'
import Messages from './Messages'
import Images from './Images'
import Magazine from './Magazine/Magazine'
import MagazineCreateOrUpdateArticle from './Magazine/MagazineCreateOrUpdateArticle'
import PageContent from './PageContent'
import { useList } from './hooks/useList'

export const WhitelistContext = createContext([])

function Main({ onLogout }) {
  const [whitelist] = useList('/whitelist')

  return (
    <WhitelistContext.Provider value={whitelist}>
      <Routes>
        <Route index element={<Navigate to="/de" replace />} />
        <Route path="/:country" element={<PageContent onLogout={onLogout} />}>
          <Route index element={<Home />} />
          <Route path="categories" element={<CategoriesTable />} />
          <Route path="brands/*" element={<BrandsOverview />} />
          <Route path="magazine">
            <Route index element={<Magazine />} />
            <Route path="new" element={<MagazineCreateOrUpdateArticle />} />
            <Route
              path="edit/:id"
              element={<MagazineCreateOrUpdateArticle />}
            />
          </Route>
          <Route path="messages" element={<Messages />} />
          <Route path="images" element={<Images />} />
        </Route>
      </Routes>
    </WhitelistContext.Provider>
  )
}

export default Main
