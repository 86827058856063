import React, { useCallback, useRef } from 'react'
import { Button, Panel, Loader, toaster, Message } from 'rsuite'

import { ref, set } from 'firebase/database'

import { css } from 'glamor-jss'
import Editor from './Editor'
import { cleanSanitizeHtml } from './utils'
import { useGetDatabase } from './db'
import { useList } from './hooks/useList'

function DescriptionEditor() {
  const [value, loading] = useList('/home/description')
  const editorRef = useRef()
  const db = useGetDatabase()

  const handleSave = useCallback(async () => {
    const html = cleanSanitizeHtml(editorRef.current.getData())

    await set(ref(db, '/home/description'), html)

    toaster.push(
      <Message type="success" showIcon closable>
        Text gespeichert.
      </Message>,
    )
  }, [db, editorRef])

  return (
    <Panel
      header="Beschreibungstext"
      bordered
      collapsible
      defaultExpanded
      {...css({ margin: 5 })}
    >
      {loading ? (
        <div style={{ position: 'relative', height: 250 }}>
          <Loader center content="Lade…" vertical />
        </div>
      ) : (
        <>
          <Editor
            onInit={(editor) => (editorRef.current = editor)}
            defaultValue={value}
            customCss=".ck-editor__editable { max-height: 250px; }"
          />
          <div
            {...css({
              marginTop: 10,
              display: 'flex',
              justifyContent: 'flex-end',
            })}
          >
            <Button onClick={handleSave}>Speichern</Button>
          </div>
        </>
      )}
    </Panel>
  )
}

export default function Home() {
  return (
    <>
      <DescriptionEditor />
      {/*<div
        {...css({
          display: 'flex',
          justifyContent: 'center',
          '> *': { flex: 1, margin: 5 },
        })}
      >
        <Panel
          header={
            <Input style={{ width: 300 }} defaultValue="Beliebte Marken" />
          }
          bordered
        >
          <div>
            {Array.from({ length: 3 }, () => (
              <div
                {...css({
                  display: 'flex',
                  alignItems: 'center',
                  '> *': { margin: 3 },
                })}
              >
                <Input placeholder="Linktext" />
                <Input placeholder="URL" />
                <Input placeholder="title" />
                <IconButton icon={<Icon icon="check" />} />
                <IconButton icon={<Icon icon="trash" />} />
              </div>
            ))}
          </div>
          <IconButton
            style={{ margin: 3 }}
            icon={<Icon icon="plus-circle" size="2x" />}
            placement="left"
          >
            Hinzufügen
          </IconButton>
        </Panel>
        <Panel header={<h3>Panel title</h3>} bordered>
          asdasd
        </Panel>
      </div>*/}
    </>
  )
}
