import React, { useEffect, useRef } from 'react'
import { Jodit } from 'jodit'
import 'jodit/build/jodit.min.css'
import './jodit_custom.css'

function MagazineEditor({ defaultValue, onChange, value, ...props }) {
  const textareaRef = useRef(null)
  const editor = useRef(null)

  useEffect(() => {
    if (!textareaRef.current) return

    editor.current = new Jodit(textareaRef.current, {
      showXPathInStatusbar: false,
      showCharsCounter: false,
      width: 720,
      colorPickerDefaultTab: 'color',
      controls: {
        font: {
          list: {
            "'Open Sans',sans-serif": 'Open Sans',
            "'Playfair Display',sans-serif": 'Playfair',
          },
        },
      },
      buttons:
        'underline,italic,|,ul,ol,|,outdent,indent,|,fontsize,brush,paragraph,image,file,video,table,link,|,align,undo,redo,\n,cut,hr,eraser,copyformat,|,symbol,fullsize,selectall',
    })

    editor.current.events.on('change', (value) => onChange(value))

    return () => editor.current.destruct()
  }, [onChange])

  useEffect(() => {
    if (!editor.current) return

    editor.current.value = defaultValue
  }, [defaultValue])

  return <textarea ref={textareaRef} />
}

export default MagazineEditor
