import React, { Fragment } from 'react'
import {
  Button,
  ButtonGroup,
  Form,
  InputPicker,
  Input,
  toaster,
  Message,
} from 'rsuite'
import DatabaseQuery from './DatabaseQuery'
import { ref, set } from 'firebase/database'
import { useGetDatabase } from './db'

import { css } from 'glamor-jss'

const FALLBACK_URL = 'https://dummyimage.com/980x300/000/fff.png'
const IMG_WIDTH = 980
const IMG_HEIGHT = 300

const style = {
  wrapper: (url) =>
    css({
      background: `url(${url || FALLBACK_URL})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      maxWidth: IMG_WIDTH,
      position: 'relative',
      overflow: 'hidden',
      ':before': {
        content: `''`,
        display: 'block',
        paddingTop: `calc(${IMG_HEIGHT} / ${IMG_WIDTH} * 100%)`,
      },
    }),
  box: css({
    width: '35%',
    background: 'rgba(0, 0, 0, 0.6)',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 10,
    userSelect: 'none',
    borderLeft: '10px solid #fff',
  }),

  h1: css({
    fontFamily: `'Playfair Display', serif`,
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 400,
  }),
}

class Images extends React.Component {
  state = {
    selectedType: 'category',
    previewUrl: '',
    femaleUrl: '',
    maleUrl: '',
    neutralUrl: '',
    selectedCategory: null,
    selectedBrand: null,
  }

  renderBrands = () => (
    <DatabaseQuery query="/brands">
      {({ loading, data }) =>
        !loading && (
          <Fragment>
            <Form.Group>
              <Form.ControlLabel>Marke</Form.ControlLabel>
              <InputPicker
                placeholder="auswählen…"
                disabled={loading}
                data={Object.values(data).map((brand) => ({
                  label: brand.name,
                  value: brand._slug,
                }))}
                onChange={(value) => this.setState({ selectedBrand: value })}
              />
            </Form.Group>
            <DatabaseQuery
              query={`/images/brands/${this.state.selectedBrand}`}
              process={(data) => data || {}}
              onFinish={(data) => {
                this.setState({
                  femaleUrl: data.femaleUrl || '',
                  maleUrl: data.maleUrl || '',
                  neutralUrl: data.neutralUrl || '',
                  previewUrl: '',
                })
              }}
            />
            {this.state.selectedBrand && (
              <Fragment>
                <Form.Group>
                  <Form.ControlLabel>URL weiblich</Form.ControlLabel>
                  <Input
                    placeholder={FALLBACK_URL}
                    style={{ width: 700, marginRight: 15 }}
                    value={this.state.femaleUrl}
                    onChange={(value) => this.setState({ femaleUrl: value })}
                  />
                  <Button
                    onClick={() =>
                      this.setState((state) => ({
                        previewUrl: state.femaleUrl,
                      }))
                    }
                  >
                    Vorschau
                  </Button>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>URL männlich</Form.ControlLabel>
                  <Input
                    placeholder={FALLBACK_URL}
                    style={{ width: 700, marginRight: 15 }}
                    value={this.state.maleUrl}
                    onChange={(value) => this.setState({ maleUrl: value })}
                  />
                  <Button
                    onClick={() =>
                      this.setState((state) => ({ previewUrl: state.maleUrl }))
                    }
                  >
                    Vorschau
                  </Button>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>URL neutral</Form.ControlLabel>
                  <Input
                    placeholder={FALLBACK_URL}
                    style={{ width: 700, marginRight: 15 }}
                    value={this.state.neutralUrl}
                    onChange={(value) => this.setState({ neutralUrl: value })}
                  />
                  <Button
                    onClick={() =>
                      this.setState((state) => ({
                        previewUrl: state.neutralUrl,
                      }))
                    }
                  >
                    Vorschau
                  </Button>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Vorschau</Form.ControlLabel>
                  <div {...style.wrapper(this.state.previewUrl)}>
                    <div {...style.box}>
                      <h1 {...style.h1}>{this.state.selectedBrand}</h1>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel />
                  <Button
                    appearance="primary"
                    onClick={async () => {
                      await set(
                        ref(
                          this.props.database,
                          `/images/brands/${this.state.selectedBrand}`,
                        ),
                        {
                          femaleUrl: this.state.femaleUrl || null,
                          maleUrl: this.state.maleUrl || null,
                          neutralUrl: this.state.neutralUrl || null,
                        },
                      )

                      toaster.push(
                        <Message type="success" showIcon closable>
                          Gespeichert
                        </Message>,
                      )
                    }}
                  >
                    Speichern
                  </Button>
                </Form.Group>
              </Fragment>
            )}
          </Fragment>
        )
      }
    </DatabaseQuery>
  )

  renderCategories = () => (
    <DatabaseQuery query="/whitelist">
      {({ loading, data }) => {
        if (loading) return null

        const currentCat = Object.values(data).find(
          (cat) => cat.slug === this.state.selectedCategory,
        )
        return (
          <Fragment>
            <Form.Group>
              <Form.ControlLabel>Kategorie</Form.ControlLabel>
              <InputPicker
                placeholder="auswählen…"
                disabled={loading}
                data={Object.values(data)
                  .filter((cat) => cat.female || cat.male)
                  .sort((a, b) => a.slug.localeCompare(b.slug))
                  .map((cat) => ({ label: cat.slug, value: cat.slug }))}
                onChange={(cat) => this.setState({ selectedCategory: cat })}
              />
            </Form.Group>
            <DatabaseQuery
              query={`/images/categories/${this.state.selectedCategory}`}
              process={(data) => data || {}}
              onFinish={(data) => {
                this.setState({
                  femaleUrl: data.femaleUrl || '',
                  maleUrl: data.maleUrl || '',
                  neutralUrl: data.neutralUrl || '',
                  previewUrl: '',
                })
              }}
            />

            {this.state.selectedCategory && (
              <Fragment>
                <Form.Group>
                  <Form.ControlLabel>
                    <span
                      style={{
                        ...(!currentCat.female
                          ? { color: 'red', textDecoration: 'line-through' }
                          : {}),
                      }}
                    >
                      URL weiblich
                    </span>
                  </Form.ControlLabel>
                  <Input
                    placeholder={FALLBACK_URL}
                    style={{ width: 700, marginRight: 15 }}
                    value={this.state.femaleUrl}
                    onChange={(value) => this.setState({ femaleUrl: value })}
                  />
                  <Button
                    onClick={() =>
                      this.setState((state) => ({
                        previewUrl: state.femaleUrl,
                      }))
                    }
                  >
                    Vorschau
                  </Button>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    <span
                      style={{
                        ...(!currentCat.male
                          ? { color: 'red', textDecoration: 'line-through' }
                          : {}),
                      }}
                    >
                      URL männlich
                    </span>
                  </Form.ControlLabel>
                  <Input
                    placeholder={FALLBACK_URL}
                    style={{ width: 700, marginRight: 15 }}
                    value={this.state.maleUrl}
                    onChange={(value) => this.setState({ maleUrl: value })}
                  />
                  <Button
                    onClick={() =>
                      this.setState((state) => ({ previewUrl: state.maleUrl }))
                    }
                  >
                    Vorschau
                  </Button>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>URL neutral</Form.ControlLabel>
                  <Input
                    placeholder={FALLBACK_URL}
                    style={{ width: 700, marginRight: 15 }}
                    value={this.state.neutralUrl}
                    onChange={(value) => this.setState({ neutralUrl: value })}
                  />
                  <Button
                    onClick={() =>
                      this.setState((state) => ({
                        previewUrl: state.neutralUrl,
                      }))
                    }
                  >
                    Vorschau
                  </Button>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Vorschau</Form.ControlLabel>
                  <div {...style.wrapper(this.state.previewUrl)}>
                    <div {...style.box}>
                      <h1 {...style.h1}>{this.state.selectedCategory}</h1>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel />
                  <Button
                    appearance="primary"
                    onClick={async () => {
                      await set(
                        ref(
                          this.props.database,
                          `/images/categories/${this.state.selectedCategory}`,
                        ),
                        {
                          femaleUrl: this.state.femaleUrl || null,
                          maleUrl: this.state.maleUrl || null,
                          neutralUrl: this.state.neutralUrl || null,
                        },
                      )

                      toaster.push(
                        <Message type="success" showIcon closable>
                          Gespeichert
                        </Message>,
                      )
                    }}
                  >
                    Speichern
                  </Button>
                </Form.Group>
              </Fragment>
            )}
          </Fragment>
        )
      }}
    </DatabaseQuery>
  )

  render() {
    return (
      <Form layout="horizontal">
        <Form.Group>
          <Form.ControlLabel>Typ</Form.ControlLabel>
          <ButtonGroup>
            <Button
              onClick={() =>
                this.setState({
                  selectedType: 'category',
                  selectedBrand: null,
                  selectedCategory: null,
                })
              }
              appearance={
                this.state.selectedType === 'category' ? 'primary' : 'ghost'
              }
            >
              Kategorien
            </Button>
            <Button
              onClick={() =>
                this.setState({
                  selectedType: 'brand',
                  selectedBrand: null,
                  selectedCategory: null,
                })
              }
              appearance={
                this.state.selectedType !== 'category' ? 'primary' : 'ghost'
              }
            >
              Marken
            </Button>
          </ButtonGroup>
        </Form.Group>
        {this.state.selectedType === 'category'
          ? this.renderCategories()
          : this.renderBrands()}
      </Form>
    )
  }
}

const ImagesWrapper = (props) => {
  const database = useGetDatabase()

  return <Images {...props} database={database} />
}

export default ImagesWrapper
