import React, { useContext, useEffect, useMemo, useState } from 'react'
import Tree from './tree.json'
import findKey from 'lodash/findKey'
import get from 'lodash/get'
import matchSorter, { rankings } from 'match-sorter'
import { css } from 'glamor-jss'

import {
  Checkbox,
  Input,
  InputGroup,
  Loader,
  Message,
  Table,
  toaster,
  Toggle,
  Tooltip,
  Whisper,
} from 'rsuite'

import { push, ref, set } from 'firebase/database'
import LegacyCloseCircleIcon from '@rsuite/icons/legacy/CloseCircle'
import LegacySearchIcon from '@rsuite/icons/legacy/Search'
import useDebounceValue from './hooks/useDebounceValue'
import useToggle from './hooks/useToggle'
import { WhitelistContext } from './Main'
import TextareaOverlayEditorCell from './TextareaOverlayEditorCell'
import { useGetDatabase } from './db'
import { useList } from './hooks/useList'

function flatten(items, result = []) {
  // eslint-disable-next-line array-callback-return
  items.map((item) => {
    if (item.children.length) {
      const { children, ...rest } = item
      result.push({ ...rest })
      flatten(children, result)
    } else {
      result.push(item)
    }
  })
  return result
}

const flattenedTree = flatten(Tree.children)

const handleCheckboxChange = (gender, db) => async (e, checked, rowData) => {
  if (rowData.firebaseId) {
    const newData = {
      ...rowData.data,
      [gender]: checked,
    }

    await set(ref(db, `/whitelist/${rowData.firebaseId}`), newData)

    // await update(ref(db), { [`/whitelist/${rowData.firebaseId}`]: newData })
  } else {
    const newData = {
      categoryId: rowData.id,
      [gender]: checked,
      slug: rowData.slug,
    }

    await push(ref(db, '/whitelist'), newData)
  }
  toaster.push(
    <Message
      type="success"
      showIcon
      closable
    >{`${rowData.slug} erfolgreich geändert.`}</Message>,
  )
}

const handleTextareaChange = (gender, db) => async (value, slug) => {
  await set(ref(db, `/descriptions/category/${slug}/${gender}`), value)

  toaster.push(
    <Message
      type="success"
      showIcon
      closable
    >{`${slug} erfolgreich geändert.`}</Message>,
  )
}

const { Column, HeaderCell, Cell } = Table

const CheckboxCell = ({
  rowData,
  onChange,
  checkedKeys,
  dataKey,
  path,
  ...props
}) => {
  const [checked, setChecked] = useState(get(rowData, path, false))

  useEffect(() => {
    setChecked(get(rowData, path, false))
  }, [path, rowData])

  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div
        {...css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        })}
      >
        <Checkbox
          checked={checked}
          onChange={(e, checked) => {
            setChecked(checked)
            onChange(e, checked, rowData)
          }}
        />
      </div>
    </Cell>
  )
}

function CategoriesTable() {
  const [descriptions, descriptionsLoading] = useList('/descriptions/category')
  const whitelist = useContext(WhitelistContext)

  const [searchValue, setSearchValue] = useState('')
  const [showEnabledOnly, toggleShowEnabledOnly] = useToggle(true)

  const isLoading = descriptionsLoading

  const processedItems = useMemo(
    () =>
      isLoading
        ? []
        : flattenedTree
            .map((item) => {
              const key = findKey(whitelist, { categoryId: item.id })
              if (key) {
                item.firebaseId = key
                item.data = whitelist[key]
              } else {
                // prefill to avoid object of undefined errors
                item.data = {}
              }
              // fallback to emtpty object,
              // again to avoid object of undefined errors
              item.descriptions = descriptions[item.slug] || {}

              return item
            })
            .filter((item) =>
              showEnabledOnly ? item.data.female || item.data.male : true,
            ),
    [descriptions, isLoading, whitelist, showEnabledOnly],
  )

  const db = useGetDatabase()
  const debouncedSearchValue = useDebounceValue(searchValue, 300)

  const filteredItems = matchSorter(processedItems, debouncedSearchValue, {
    threshold: rankings.WORD_STARTS_WITH,
    keys: ['name', 'id', 'descriptions.female', 'descriptions.male'],
  })

  return isLoading ? (
    <Loader center content="Lade…" vertical />
  ) : (
    <>
      <InputGroup inside style={{ width: 300 }}>
        <Input
          autoFocus
          placeholder="Alle Daten durchsuchen…"
          value={searchValue}
          onChange={(val) => setSearchValue(val)}
        />
        <InputGroup.Addon
          style={{ cursor: 'pointer' }}
          onClick={() => setSearchValue('')}
        >
          {searchValue ? <LegacyCloseCircleIcon /> : <LegacySearchIcon />}
        </InputGroup.Addon>
      </InputGroup>
      <Table
        style={{ marginTop: 20, maxWidth: 1260 }}
        virtualized
        height={700}
        bordered
        cellBordered
        rowHeight={120}
        headerHeight={80}
        data={filteredItems}
      >
        <Column width={160}>
          <HeaderCell align="center">ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>

        <Column width={220}>
          <HeaderCell align="center">Kategorie</HeaderCell>
          <Cell dataKey="name">
            {(rowData) => `${'-'.repeat(rowData.depth)} ${rowData.name}`}
          </Cell>
        </Column>

        <Column width={60} colSpan={2} align="center">
          <HeaderCell className="header-cell-group" align="center">
            <div className="header-cell-group-title">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                Aktiviert
                <Whisper
                  placement="right"
                  speaker={
                    <Tooltip>
                      Wenn aktiv werden nur Kategorien angezeigt, die mindestens
                      ein Geschlecht als aktiv markiert haben.
                    </Tooltip>
                  }
                >
                  <Toggle
                    checked={showEnabledOnly}
                    onChange={toggleShowEnabledOnly}
                    size="sm"
                  />
                </Whisper>
              </div>
            </div>
            <div className="header-cell-group-subtitle">
              <span>Damen</span>
              <span>Herren</span>
            </div>
          </HeaderCell>
          <CheckboxCell
            dataKey="id"
            path="data.female"
            onChange={handleCheckboxChange('female', db)}
          />
        </Column>

        <Column width={60}>
          <HeaderCell />
          <CheckboxCell
            dataKey="id"
            path="data.male"
            onChange={handleCheckboxChange('male', db)}
          />
        </Column>

        <Column width={60} colSpan={2} flexGrow={1}>
          <HeaderCell className="header-cell-group" align="center">
            <div className="header-cell-group-title">Beschreibung</div>
            <div className="header-cell-group-subtitle">
              <span>Damen</span>
              <span>Herren</span>
            </div>
          </HeaderCell>
          <TextareaOverlayEditorCell
            dataKey="id"
            path="descriptions.female"
            placeholder="Kategorientext für Damen"
            onChange={handleTextareaChange('female', db)}
          />
        </Column>

        <Column width={60} flexGrow={1}>
          <HeaderCell />
          <TextareaOverlayEditorCell
            dataKey="id"
            path="descriptions.male"
            placeholder="Kategorientext für Herren"
            onChange={handleTextareaChange('male', db)}
          />
        </Column>
      </Table>
    </>
  )
}

export default CategoriesTable
