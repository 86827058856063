import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { Container, Content, Header, Nav, Navbar } from 'rsuite'
import LegacySignOutIcon from '@rsuite/icons/legacy/SignOut'
import { css } from 'glamor-jss'

import AdminImage from './admin.png'
import CountryChooser from './CountryChooser'

const PageContent = ({ onLogout }) => {
  return (
    <Container>
      <Header>
        <Navbar>
          <Navbar.Brand
            to=""
            as={Link}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 100,
            }}
          >
            <img src={AdminImage} alt="Admin" height={35} />
          </Navbar.Brand>
          <Nav>
            <Nav.Item as={Link} to="">
              Startseite
            </Nav.Item>
            <Nav.Item as={Link} to="categories">
              Kategorien
            </Nav.Item>
            <Nav.Item as={Link} to="brands">
              Marken
            </Nav.Item>
            <Nav.Item as={Link} to="magazine">
              Magazin
            </Nav.Item>
            <Nav.Item as={Link} to="messages">
              Metas
            </Nav.Item>
            <Nav.Item as={Link} to="images">
              Bilder
            </Nav.Item>
          </Nav>
          <Nav pullRight>
            <CountryChooser />
            <Nav.Item href="https://www.maprodo.de" target="_blank">
              zu Maprodo
            </Nav.Item>
            <Nav.Item icon={<LegacySignOutIcon />} onClick={onLogout}>
              Logout
            </Nav.Item>
          </Nav>
        </Navbar>
      </Header>
      <Content {...css({ padding: 20 })}>
        <Outlet />
      </Content>
    </Container>
  )
}

export default PageContent
