import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'rsuite'

import Editor from './Editor'
import { cleanSanitizeHtml } from './utils'

function OverlayEditor({ show, value, onRequestClose, onSave }) {
  const editorRef = useRef()

  const shouldCloseDirty = useCallback(() => {
    const html = editorRef.current.getData()
    const isSame = cleanSanitizeHtml(html) === cleanSanitizeHtml(value)

    return (
      isSame || (!isSame && window.confirm('Fenster ohne speichern schließen?'))
    )
  }, [value, editorRef])

  const handleSave = () => {
    const html = cleanSanitizeHtml(editorRef.current.getData())
    onSave(html)
    onRequestClose()
  }

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => shouldCloseDirty() && onRequestClose()}
      overflow
      style={{ height: '75vh' }}
    >
      <Modal.Header />
      <Modal.Body>
        <Editor
          defaultValue={value}
          onInit={(editor) => (editorRef.current = editor)}
          config={{ placeholder: 'Text einfügen' }}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onRequestClose} appearance="subtle">
          Abbrechen
        </Button>
        <Button onClick={handleSave} appearance="primary">
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

OverlayEditor.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default OverlayEditor
