import React, { useContext } from 'react'
import { Table } from 'rsuite'

import { WhitelistContext } from './Main'

const { Column, HeaderCell, Cell } = Table

export default function BrandsDetail() {
  const whitelist = useContext(WhitelistContext)

  return (
    <Table autoHeight bordered cellBordered data={whitelist}>
      <Column>
        <HeaderCell>ID</HeaderCell>
        <Cell dataKey="id" />
      </Column>
      <Column width={160}>
        <HeaderCell>Slug</HeaderCell>
        <Cell dataKey="slug" />
      </Column>
    </Table>
  )
}
