import React, { createContext, useCallback, useEffect, useState } from 'react'

import { sha256 } from 'js-sha256'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import firebaseApp from './firebaseApp'
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signOut,
} from 'firebase/auth'
import 'rsuite/dist/rsuite.min.css'
import './base.css'
import Main from './Main'

const LOGIN_USER = [
  // MAPRODO
  '675ee36c3c25c367aa595f063b0a026898047e07e441cb038801b3a0bea362e5',
  // X-TINA
  'a314a0381b39fc99259291a09fde074bba1c68ac5c549bf95a1b83076071697e',
]

export const UserContext = createContext(undefined)

const App = () => {
  const auth = getAuth(firebaseApp)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [signedInUser, setSignedInUser] = useState(null)

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      if (user && LOGIN_USER.includes(sha256(user.uid))) {
        setIsSignedIn(Boolean(user))
        setSignedInUser(user)
      }
      setIsLoaded(true)
    })

    return () => unregisterAuthObserver()
  }, [auth])

  const handleLogout = useCallback(() => {
    signOut(auth).then(() => setIsSignedIn(false))
  }, [auth])

  return !isLoaded ? null : !isSignedIn ? (
    <StyledFirebaseAuth
      uiConfig={{
        signInFlow: 'popup',
        signInOptions: [GoogleAuthProvider.PROVIDER_ID],
      }}
      firebaseAuth={auth}
    />
  ) : (
    <UserContext.Provider value={signedInUser}>
      <Main onLogout={handleLogout} />
    </UserContext.Provider>
  )
}

export default App
