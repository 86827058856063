import React, { useEffect, useState } from 'react'
import { Input, Table } from 'rsuite'
import { css } from 'glamor-jss'
import get from 'lodash/get'
import memoize from 'lodash/memoize'

import OverlayEditor from './OverlayEditor'

const countHtmlWords = memoize((html) => {
  const div = document.createElement('div')
  div.innerHTML = html
  const match = div.innerText.match(/\S+/g)

  return match ? match.length : 0
})

export default function TextareaOverlayEditorCell({
  rowData,
  // selector path for the object
  // dataKey does not work because it needs the value actual to be truthy!
  // if this is not the case, the cell is NOT rendered 😒
  // => use an dataKey which will always be truthy as a hack
  path,
  onClick,
  onChange,
  placeholder,
  ...props
}) {
  const [showEditor, setShowEditor] = useState(false)
  const [value, setValue] = useState(() => get(rowData, path, ''))

  useEffect(() => {
    setValue(get(rowData, path, ''))
  }, [rowData, path])

  return (
    <Table.Cell {...props}>
      <Input
        as="textarea"
        rows={3}
        value={value}
        readOnly
        onClick={() => setShowEditor(true)}
        style={{ width: '100%', minWidth: 'unset', cursor: 'pointer' }}
        placeholder={placeholder}
      />
      <span {...css({ color: '#9c9c9c', fontSize: 12 })}>
        {countHtmlWords(value)} Wörter
      </span>
      <OverlayEditor
        show={showEditor}
        onRequestClose={() => setShowEditor(false)}
        value={value}
        onSave={(html) => {
          setValue(html)
          onChange(html, rowData)
        }}
      />
    </Table.Cell>
  )
}
