import React, { useMemo, useState } from 'react'
import {
  toaster,
  Message,
  Button,
  Input,
  ButtonToolbar,
  InputGroup,
  Loader,
  Table,
} from 'rsuite'
import LegacyPlusIcon from '@rsuite/icons/legacy/Plus'
import fetch from 'cross-fetch'
import get from 'lodash/get'
import { buildApiUrl, updateBrandCategories } from './getAllCategoriesByBrandId'
import buildUrl from 'build-url'
import accesskey from './accesskey'
import { Link } from 'react-router-dom'
import TextareaOverlayEditorCell from './TextareaOverlayEditorCell'
import generateSlug from './generateSlug'
import { ref, set, remove } from 'firebase/database'
import { useGetDatabase } from './db'
import { useList } from './hooks/useList'

const addBrandToDatabase = async (db, id) => {
  const response = await fetch(
    buildUrl(`https://api.tracdelight.io/v2`, {
      path: `brands/${id}`,
      queryParams: { accesskey, format: 'json' },
    }),
  )

  if (!response.ok) {
    throw Error('ID not found')
  }

  const data = await response.json()

  const _slug = generateSlug(data.name)
  const newBrand = {
    id: data.id,
    name: data.name,
    _slug,
  }

  await set(ref(db, `/brands/${_slug}`), newBrand)

  const categories = await updateBrandCategories(newBrand, db)

  return { data, categories }
}

const handleClickDelete = async (db, _slug) => {
  if (window.confirm(`Marke '${_slug}' wirklich löschen?`)) {
    await remove(ref(db, `/brands/${_slug}`))
    await remove(ref(db, `/brand-categories/${_slug}`))

    toaster.push(
      <Message type="success" showIcon closable>
        Marke wurde gelöscht
      </Message>,
    )
  }
}

const handleTextareaChange = (gender, db) => async (html, brand) => {
  await set(ref(db, `/descriptions/brand/${brand._slug}/${gender}`), html)

  toaster.push(
    <Message
      type="success"
      showIcon
      closable
    >{`${brand.name} erfolgreich geändert.`}</Message>,
  )
}

function AddBrandInput() {
  const [newBrandId, setNewBrandId] = useState('')
  const [isAddingNewBrand, setIsAddingNewBrand] = useState(false)
  const db = useGetDatabase()

  const handleClickAdd = async () => {
    if (!newBrandId) {
      toaster.push(
        <Message type="warning" showIcon closable>
          Bitte ID angeben.
        </Message>,
      )
      return
    }

    setIsAddingNewBrand(true)
    try {
      const { data, categories } = await addBrandToDatabase(db, newBrandId)

      setNewBrandId('')
      toaster.push(
        <Message
          type="success"
          showIcon
          closable
        >{`Marke '${data.name}' wurde mit ${categories.added} Kategorien angelegt`}</Message>,
      )
    } catch (e) {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
        >{`Fehler: ${e.message}`}</Message>,
      )
      throw e
    }
    setIsAddingNewBrand(false)
  }

  return (
    <InputGroup inside style={{ width: 300 }}>
      <Input
        autoFocus
        value={newBrandId}
        onChange={(id) => setNewBrandId(id)}
        placeholder="Brand-ID hinzufügen"
        disabled={isAddingNewBrand}
      />
      <InputGroup.Button disabled={isAddingNewBrand}>
        {isAddingNewBrand ? (
          <Loader />
        ) : (
          <LegacyPlusIcon onClick={handleClickAdd} />
        )}
      </InputGroup.Button>
    </InputGroup>
  )
}

const { Column, HeaderCell, Cell } = Table

export default function BrandsTable({ brands }) {
  const [brandCategoriesUpdating, setBrandCategoriesUpdating] = useState(null)
  const [brandDescriptions, loading] = useList('/descriptions/brand')
  const db = useGetDatabase()

  const brandsWithDescriptions = useMemo(
    () =>
      brands.map((brand) => ({
        ...brand,
        descriptions: get(brandDescriptions, brand._slug, {}),
      })),
    [brandDescriptions, brands],
  )

  return (
    !loading && (
      <>
        <AddBrandInput />

        <Table
          style={{ marginTop: 20, maxWidth: 1260 }}
          bordered
          cellBordered
          rowHeight={120}
          headerHeight={80}
          autoHeight
          rowKey="id"
          data={brandsWithDescriptions}
        >
          <Column width={200}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name">
              {(brand) => (
                <div>
                  <Link to={brand._slug}>{brand.name}</Link>
                  <span style={{ fontSize: '80%', fontStyle: 'italic' }}>
                    <br />
                    <br />
                    Tracdelight:{' '}
                    <a
                      href={buildApiUrl({
                        brand_id: brand.id,
                        page_size: 20,
                        format: 'json',
                      })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {brand.id}
                    </a>
                  </span>
                </div>
              )}
            </Cell>
          </Column>

          <Column colSpan={3} flexGrow={1}>
            <HeaderCell className="header-cell-group" align="center">
              <div className="header-cell-group-title">Beschreibung</div>
              <div className="header-cell-group-subtitle">
                <span>Damen</span>
                <span>Herren</span>
                <span>Neutral</span>
              </div>
            </HeaderCell>
            <TextareaOverlayEditorCell
              dataKey="id"
              path="descriptions.female"
              placeholder="Damen-Markentext"
              onChange={handleTextareaChange('female', db)}
            />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell />
            <TextareaOverlayEditorCell
              dataKey="id"
              path="descriptions.male"
              placeholder="Herren-Markentext"
              onChange={handleTextareaChange('male', db)}
            />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell />
            <TextareaOverlayEditorCell
              dataKey="id"
              path="descriptions.neutral"
              placeholder="Neutraler Markentext"
              onChange={handleTextareaChange('neutral', db)}
            />
          </Column>

          <Column flexGrow={1} fixed="right">
            <HeaderCell>Aktionen</HeaderCell>

            <Cell>
              {(brand) => (
                <ButtonToolbar>
                  <Button
                    appearance="default"
                    disabled={brandCategoriesUpdating !== null}
                    loading={brandCategoriesUpdating === brand.id}
                    size="sm"
                    onClick={async () => {
                      setBrandCategoriesUpdating(brand.id)
                      const categories = await updateBrandCategories(brand, db)

                      toaster.push(
                        <Message type="success" showIcon closable>{
                          // prettier-ignore
                          `${categories.added} Kategorien hinzugefügt/aktualisiert und ${categories.deleted} gelöscht`
                        }</Message>,
                      )
                      setBrandCategoriesUpdating(null)
                    }}
                  >
                    Kategorien updaten
                  </Button>
                  <Button
                    color="orange"
                    appearance="ghost"
                    size="sm"
                    onClick={() => handleClickDelete(db, brand._slug)}
                  >
                    Löschen
                  </Button>
                </ButtonToolbar>
              )}
            </Cell>
          </Column>
        </Table>
      </>
    )
  )
}
