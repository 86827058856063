import React, { useEffect, useState } from 'react'
import { toaster, Message, Button, ButtonToolbar, Table, Tag } from 'rsuite'
import { onValue, ref, query, remove, orderByChild } from 'firebase/database'
import { useNavigate } from 'react-router'
import { useGetDatabase } from '../db'

const { Column, HeaderCell, Cell } = Table

function Magazine() {
  const [isLoading, setIsLoading] = useState(false)
  const [articles, setArticles] = useState()
  const navigate = useNavigate()
  const db = useGetDatabase()

  useEffect(() => {
    setIsLoading(true)

    const queryRef = query(
      ref(db, '/magazine/article_list'),
      orderByChild('published_at'),
    )

    onValue(queryRef, (snapshot) => {
      const data = []
      snapshot.forEach((snap) => {
        data.push({ $id: snap.key, ...snap.val() })
      })
      setIsLoading(false)
      setArticles(data.reverse())
    })
    // .orderByChild('published_at')
  }, [db])

  const handleDeleteArticle = (id) => async () => {
    if (window.confirm(`Artikel wirklich löschen?`)) {
      await remove(ref(db, `/magazine/article_list/${id}`))
      await remove(ref(db, `/magazine/articles/${id}`))

      toaster.push(
        <Message type="success" showIcon closable>
          Artikel wurde gelöscht
        </Message>,
      )
    }
  }

  return (
    <>
      <Button appearance="primary" onClick={() => navigate('new')}>
        Artikel erstellen
      </Button>
      <Table
        loading={isLoading}
        style={{ marginTop: 20, maxWidth: 1480 }}
        bordered
        cellBordered
        autoHeight
        rowHeight={60}
        data={articles}
        rowKey="$id"
      >
        <Column flexGrow={3}>
          <HeaderCell>Title</HeaderCell>
          <Cell dataKey="title" />
        </Column>
        <Column width={180}>
          <HeaderCell>Autor</HeaderCell>
          <Cell dataKey="author" />
        </Column>
        <Column width={160}>
          <HeaderCell>Bearbeitet</HeaderCell>
          <Cell dataKey="updated_at">
            {(article) => new Date(article.updated_at).toLocaleString('de-DE')}
          </Cell>
        </Column>
        <Column width={160}>
          <HeaderCell>Erstellt</HeaderCell>
          <Cell dataKey="published_at">
            {(article) =>
              new Date(article.published_at).toLocaleString('de-DE')
            }
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Kategorie</HeaderCell>
          <Cell dataKey="category">
            {(article) => <Tag>{article.category}</Tag>}
          </Cell>
        </Column>
        <Column width={240} fixed="right">
          <HeaderCell>Action</HeaderCell>

          <Cell>
            {(article) => {
              return (
                <ButtonToolbar>
                  <Button
                    appearance="primary"
                    onClick={() => navigate(`edit/${article.$id}`)}
                  >
                    Bearbeiten
                  </Button>
                  <Button
                    appearance="default"
                    onClick={handleDeleteArticle(article.$id)}
                  >
                    Löschen
                  </Button>
                </ButtonToolbar>
              )
            }}
          </Cell>
        </Column>
      </Table>
    </>
  )
}

export default Magazine
