import React from 'react'
import { Nav } from 'rsuite'
import { Link, useLocation, useMatch } from 'react-router-dom'

const CountryChooser = () => {
  const location = useLocation()
  const match = useMatch('/:country/*')
  const currentPath = match.params['*'] ? '/' + match.params['*'] : ''

  const options = [
    { label: '🇩🇪 Deutschland', basePath: '/de' },
    { label: '🇨🇭 Schweiz', basePath: '/ch' },
    { label: '🇦🇹 Österreich', basePath: '/at' },
  ].map((item) => ({
    ...item,
    isActive: location.pathname.startsWith(item.basePath),
  }))

  return (
    <Nav.Dropdown
      title={options.find((item) => item.isActive)?.label ?? ''}
      placement="bottomStart"
    >
      {options.map(({ label, basePath, isActive }) => (
        <Nav.Dropdown.Item
          as="a"
          href={basePath + currentPath}
          key={basePath}
          active={isActive}
        >
          {label}
        </Nav.Dropdown.Item>
      ))}
    </Nav.Dropdown>
  )
}

export default CountryChooser
