import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor-jss'
import { ref, set } from 'firebase/database'

import { useGetDatabase } from './db'
import { useObjectVal } from 'react-firebase-hooks/database'

const SimpleEditor = ({ multiline, onSave, ...props }) => {
  const textareaRef = useRef()

  return (
    <div {...css({ display: 'flex' })}>
      <textarea
        ref={textareaRef}
        {...props}
        onKeyDown={
          !multiline
            ? (e) => e.key === 'Enter' && e.preventDefault()
            : undefined
        }
      />
      <button onClick={() => onSave(textareaRef.current.value)}>
        Speichern
      </button>
    </div>
  )
}

SimpleEditor.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  multiline: PropTypes.bool,
}

export default function Messages() {
  const db = useGetDatabase()
  const [messages, loading] = useObjectVal(ref(db, '/messages'))

  return (
    !loading && (
      <table {...css({ width: '100%' })}>
        <tbody>
          {Object.entries(messages).map(([key, translation]) => (
            <tr key={key}>
              <td>{key}</td>
              <td {...css({ width: '100%' })}>
                <SimpleEditor
                  defaultValue={translation}
                  {...css({ marginLeft: 20, padding: 7, width: '95%' })}
                  onSave={(value) => set(ref(db, `/messages/${key}`), value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  )
}
