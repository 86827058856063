import fetch from 'cross-fetch'
import buildUrl from 'build-url'
import uniqBy from 'lodash/uniqBy'
import intersectionWith from 'lodash/intersectionWith'
import accesskey from './accesskey'
import differenceBy from 'lodash/differenceBy'
import * as Parallel from 'async-parallel-es5'
import { onValue, ref } from 'firebase/database'

export const buildApiUrl = (params) =>
  buildUrl('https://api.tracdelight.io/v2/products', {
    queryParams: {
      accesskey,
      faceting: 'category_id',
      faceting_limit: 0,
      page_size: 1,
      price_min: 50,
      ...params,
    },
  })

// GENERATOR IMPLEMENTATION
// ASYNC GENS NOT YET SUPPORTED
// https://github.com/facebook/create-react-app/pull/4222
// async function* genGetAllCategoriesByBrandId(
//   brand_id,
//   gender,
//   categories = []
// ) {
//   const url = buildApiUrl({
//     brand_id,
//     gender,
//     category_id: categories.length
//       ? categories.map(c => c.id).join(',')
//       : undefined,
//   })
//
//   const request = await fetch(url)
//   if (request.status !== 200) {
//     throw request
//   }
//   const response = await request.json()
//   if (response.facets) {
//     const responseCategories = response.facets.category_id
//
//     if (responseCategories.length !== categories.length) {
//       categories = uniqBy([...categories, ...responseCategories], 'id')
//       yield categories.length
//       yield* genGetAllCategoriesByBrandId(categories)
//     } else return categories
//   }
// }

const fetchWithTimeout = (url, options, timeout = 10000) =>
  Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(
        () =>
          reject(
            new Error(`Timed out after ${timeout}ms while requesting ${url}`),
          ),
        timeout,
      ),
    ),
  ])

export const getAllCategoriesByBrandId = (brand_id, gender) =>
  new Promise(async (resolve, reject) => {
    // for emergency exit if endless loop occurs
    const MAX_REQUESTS = 8
    let requests = 0
    let categories = []

    const fetchAll = async () => {
      const url = buildApiUrl({
        brand_id,
        gender,
        category_id: categories.map((c) => c.id).join(','),
      })

      try {
        const request = await fetchWithTimeout(url)
        if (request.status !== 200) {
          reject(request)
        }

        const response = await request.json()

        if (response.facets) {
          const responseCategories = response.facets.category_id
          if (
            responseCategories.length !== categories.length &&
            ++requests < MAX_REQUESTS
          ) {
            categories.push(...responseCategories)
            categories = uniqBy(categories, 'id')
            await fetchAll()
          } else {
            if (requests >= MAX_REQUESTS) {
              console.warn(
                'Max requests reached',
                brand_id,
                gender,
                url,
                responseCategories.length,
                categories.length,
                '\n',
              )
            }
            resolve(categories)
          }
        }
      } catch (e) {
        reject(e)
      }
    }
    await fetchAll()
  })

export const getWhitelist = (db) =>
  new Promise((resolve) => {
    onValue(
      ref(db, 'whitelist'),
      (snapshot) => resolve(Object.values(snapshot.val())),
      { onlyOnce: true },
    )
  })

const filterByWhitelist = (categories, whitelist, gender) =>
  intersectionWith(
    categories,
    whitelist,
    (api, db) => db[gender] === true && api.id === db.categoryId,
  )

export const updateBrandCategories = async (brand, db, whitelist) => {
  if (!whitelist) {
    whitelist = await getWhitelist(db)
  }
  const count = { added: 0, deleted: 0 }
  for (let i = 0; i < 2; i++) {
    const apiGender = i === 0 ? 'weiblich' : 'männlich'
    const dbGender = i === 0 ? 'female' : 'male'

    const categories = await getAllCategoriesByBrandId(brand.id, apiGender)
    const filteredCategories = filterByWhitelist(
      categories,
      whitelist,
      dbGender,
    )

    let oldBrandCategories = (
      await db.ref(`/brand-categories/${dbGender}/${brand._slug}`).once('value')
    ).val()

    // if category did not exist before
    if (oldBrandCategories) {
      oldBrandCategories = Object.values(oldBrandCategories)
      const categoriesToDelete = differenceBy(
        oldBrandCategories,
        filteredCategories,
        'slug',
      )

      await Parallel.each(categoriesToDelete, async (cat) => {
        await db
          .ref(`/brand-categories/${dbGender}/${brand._slug}/${cat.slug}`)
          .remove()

        await db
          .ref(`/category-brands/${cat.slug}/${brand._slug}/${dbGender}`)
          .remove()
        count.deleted++
      })
    }

    await Parallel.each(filteredCategories, async (cat) => {
      await db
        .ref(`/brand-categories/${dbGender}/${brand._slug}/${cat.slug}`)
        .set(cat)

      await db
        .ref(`/category-brands/${cat.slug}/${brand._slug}/${dbGender}`)
        .set(true)
      count.added++
    })
  }
  return count
}
