import React from 'react'
import { Route, Routes } from 'react-router'
import { Loader } from 'rsuite'

import BrandsDetail from './BrandsDetail'
import BrandsTable from './BrandsTable'
import { useList } from './hooks/useList'

export default function BrandsOverview() {
  const [brands, brandsLoading] = useList('/brands')
  const [categoryDescriptions, categoryDescriptionsLoading] = useList(
    '/descriptions/brand-category',
  )

  const isLoading = brandsLoading || categoryDescriptionsLoading
  const brandValues = !brandsLoading && Object.values(brands)

  return isLoading ? (
    <Loader center content="Lade…" vertical />
  ) : (
    <Routes>
      <Route
        index
        element={
          <BrandsTable
            brands={brandValues}
            categoryDescriptions={categoryDescriptions}
          />
        }
      />
      <Route path=":slug" exact element={<BrandsDetail />} />
    </Routes>
  )
}
