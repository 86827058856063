import React from 'react'
import PropTypes from 'prop-types'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-react'

const Editor = ({
  onChange,
  onInit = () => {},
  initialValue,
  config,
  customCss,
}) => (
  <>
    {customCss && <style>{customCss}</style>}
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'undo', 'redo'],
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },

            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading2',
            },
          ],
        },
        ...config,
      }}
      onInit={(editor) => {
        editor.setData(initialValue)
        onInit(editor)
      }}
      onChange={onChange}
    />
  </>
)

Editor.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  config: PropTypes.object,
  customCss: PropTypes.string,
}

export default Editor
