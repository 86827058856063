import { useEffect, useState } from 'react'

function useDebounceValue(value, wait) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedValue(value), wait)

    return () => clearTimeout(timeoutId)
  }, [value, wait])

  return debouncedValue
}

export default useDebounceValue
