import { getDatabase as getFirebaseDatabase } from 'firebase/database'
import { matchPath } from 'react-router'
import { useMatch } from 'react-router-dom'
import { useEffect, useState } from 'react'
import app from './firebaseApp'

export const getDatabaseUrl = (domain = 'de') =>
  `https://maprodo-${domain}.europe-west1.firebasedatabase.app`

export const useGetDatabase = () => {
  const match = useMatch('/:country/*')
  const databaseUrl = getDatabaseUrl(match?.params?.country)

  const [database, setDatabase] = useState(() =>
    getFirebaseDatabase(app, databaseUrl),
  )

  useEffect(() => {
    setDatabase(getFirebaseDatabase(app, databaseUrl))
  }, [databaseUrl])

  return database
}

export const getDatabase = () => {
  const match = matchPath('/:country/*', new URL(window.location.href).pathname)
  const databaseUrl = getDatabaseUrl(match.params.country)

  return getFirebaseDatabase(undefined, databaseUrl)
}
