import React from 'react'
import PropTypes from 'prop-types'
import { onValue, ref } from 'firebase/database'
import { useGetDatabase } from './db'

export const query = (db, refString) =>
  new Promise((resolve) => {
    onValue(ref(db, refString), (snapshot) => resolve(snapshot.val()), {
      onlyOnce: true,
    })
  })

class DatabaseQuery extends React.Component {
  static propTypes = {
    query: PropTypes.string.isRequired,
    database: PropTypes.any.isRequired,
    children: PropTypes.func,
    process: PropTypes.func,
    onFinish: PropTypes.func,
  }

  static defaultProps = {
    process: (data) => data,
    onFinish: () => {},
  }

  state = {
    loading: true,
    data: undefined,
  }

  fetch = async () => {
    this.setState({ loading: true })
    const data = this.props.process(
      await query(this.props.database, this.props.query),
    )
    this.props.onFinish(data)
    this.setState({ loading: false, data })
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (this.props.query !== prevProps.query) {
      this.fetch()
    }
  }

  render() {
    return this.props.children
      ? this.props.children({
          loading: this.state.loading,
          data: this.state.data,
          refetch: this.fetch,
          query,
        })
      : null
  }
}

const DatabaseQueryWrapper = (props) => {
  const database = useGetDatabase()

  return <DatabaseQuery {...props} database={database} />
}

export default DatabaseQueryWrapper
